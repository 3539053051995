import React, {Component} from 'react'

import Header from '../components/Header/header';
import HeroSection from '../components/HeroSection/heroSectionQuote';
import RequestContract from '../components/Contact/RequestContract';
import Footer from '../components/footer/footer';


class ContractApp extends Component{
    render(){
	  let plan = this.props.match.params.plan;
      const {description} = this.props;
        return(
            <div>
                <Header />
                <div className="main">
                    <HeroSection title={ 'Plan de desarrollo app ' + plan } description={description || 'Rellena este formulario y nos ponemos de inmediato en contacto'} backgroundImage='https://ik.imagekit.io/itdo/itdo-app_s5lr3j6uU.jpeg'  />
                    <RequestContract 
                    	url_success_form = "/muchas-gracias-desarrollo-app" 
                    	email_template = "welcome_to_costumer_app"
                    />
                </div>
                <Footer />
            </div>
        )
    }
}

export default ContractApp;
