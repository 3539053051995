import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// importing all the themes
import ThemeITDO from "../themes/itdo";
import ThemeITDOApp from "../themes/itdo-app";
import ThemeITDOCustomWeb from "../themes/itdo-custom-web";
import contact from '../themes/contact';
import Quote from '../themes/quote';
import QuoteGeneral from '../themes/quotecontact';
import Contract from '../themes/contract';
import ContractApp from '../themes/contractApp';
import Thanks from '../themes/thanks';
import ThanksApp from '../themes/thanksApp';
import Contact from '../themes/contact';
import ThanksContact from '../themes/thanksContact';

import Portafolio from "../themes/portafolio";
import Blog from "../components/Blog";

export default class Routes extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact path="/" component={ThemeITDO} />
            <Route exact path="/desarrollo-app" component={ThemeITDOApp} />
            <Route exact path="/desarrollo-web-avanzado-a-medida" component={ThemeITDOCustomWeb} />
			      <Route exact path="/contact" component={contact} />
            <Route exact path="/pedir-presupuesto/desarrollo-web" component={Quote} />            
            <Route exact path="/pedir-presupuesto/:service" component={QuoteGeneral} />
            <Route exact path="/contratar-desarrollo-web/:plan" component={Contract} />
            <Route exact path="/contratar-desarrollo-app/:plan" component={ContractApp} />
            <Route exact path="/muchas-gracias-desarrollo-web" component={Thanks} />
            <Route exact path="/muchas-gracias-desarrollo-app" component={ThanksApp} />
            <Route exact path="/muchas-gracias" component={ThanksContact} />
            <Route exact path="/contacto" component={Contact} />

            <Route exact path="/portafolio" component={Portafolio} />
            <Route exact path="/blog" component={Blog} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}
