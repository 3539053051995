import React, {Component} from 'react'
import { connect } from "react-redux";
import Header from '../components/Header/header';
import HeroSection from '../components/HeroSection/heroSectionQuoteContact';
import RequestQuoteSection from '../components/Quote/Contact';
import Footer from '../components/footer/footer';
import Notifications from "react-notify-toast";
import {
	withRouter
} from 'react-router-dom';

class Quote extends Component{
    render(){
      const {description} = this.props;
      let service = this.props.match.params.service;
      var output;
      var backgroundImage;
	  switch (service) {
	      case "desarrollo-web-a-medida":
	        output = "Desarrollo Web a Medida";
	        backgroundImage='https://ik.imagekit.io/itdo/ITDO-freelance6_xH56McWwC.jpg'
	        break;
	      case "desarrollo-app":
	        output = "Desarrollo APP";
	        backgroundImage = 'https://ik.imagekit.io/itdo/itdo-app_s5lr3j6uU.jpeg';
	        break;
	      case "automatizacion-erp-crm":
	        output = "Automatización: Integración ERP y/o CRM";
	        break;
	      case "diseno-web-ui-ux":
	        output = "Diseño web UI/UX";
	        break;
	      case "por-que-no-posiciona-mi-web":
	        output = "¿Por que no posiciona mi web?";
	        break;
	      case "auditoria-posicionamiento-SEO":
	        output = "Auditoria y posicionamiento SEO";
	        break;
	      case "analisis-redes-sociales":
	        output = "Análisis de Redes Sociales";
	        break;
	      case "captacion-fidelizacion-clientes-CRM":
	        output = "Captación y fidelización de clientes CRM";
	        break;
	      default:
	        output = "";
	        backgroundImage='https://ik.imagekit.io/itdo/ITDO-freelance6_xH56McWwC.jpg'
	        break;
	    }
        return(
            <div>
                <Header />
                <div className="main">
                    <HeroSection title={'Pide un presupuesto '} service={output} description={description || 'Rellena este formulario y tendrás tu presupuesto en menos de 24h'} backgroundImage={backgroundImage} />
                   
                    <React.Fragment>
				        <section className="contact-us-section ptb-100-none gray-light-bg" >
				        <Notifications options={{zIndex: 200}} />
				          <div className="container">
				            <div className="row justify-content-between align-items-center">
				            <div className="col-md-2"></div>
				              <div className="col-md-8">
				                 <RequestQuoteSection pathname={this.props.history.location.pathname} />
				                <p className="form-message" />
				              </div>
				              <div className="col-md-2"></div>
				            </div>
				          </div>
				        </section>
				      </React.Fragment>
                </div>
                <Footer />
            </div>
        )
    }
}

export default connect(state => ({
  state
}))(withRouter(Quote));