import React, {Component} from 'react'

import Header from '../components/Header/header';
import HeroSection from '../components/HeroSection/heroSectionQuote';
import RequestQuoteSection from '../components/Contact/RequestQuote';
import Footer from '../components/footer/footer';


class Quote extends Component{
    render(){
      const {description} = this.props;
        return(
            <div>
                <Header />
                <div className="main">
                    <HeroSection title='Pide un presupuesto' description={description || 'Rellena este formulario y tendrás tu presupuesto en menos de 24h'} backgroundImage='https://ik.imagekit.io/itdo/ITDO-freelance6_xH56McWwC.jpg' />
                    <RequestQuoteSection />
                </div>
                <Footer />
            </div>
        )
    }
}

export default Quote;
