import React from "react";
import _data from "../../data";

class HeroSectionQuoteContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hero: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      hero: _data.heroAboutUs
    });
  }

  render() {
    const {backgroundImage = 'https://ik.imagekit.io/itdo/ITDO-freelance6_xH56McWwC.jpg', description, title, service} = this.props;
    return (
      <React.Fragment>
        <section
          className="hero-section hero-quote background-img ptb-100"
          style={{
            background: `url(${backgroundImage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "0 -400px",
            backgroundSize: "center / cover",
            backgroundAttachment: ""
          }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-7">
                <div className="page-header-content text-white text-center pt-5">
                  <h1 className="text-white mb-1">{ title ? title : this.state.hero.title }</h1>
                  <h1 className="text-white mb-1">{ service ? service : this.state.hero.service }</h1>
                  <p className="description lead"> {description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default HeroSectionQuoteContact;
