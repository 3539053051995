import React from "react";
import _data from "../../data";

class Portafolio4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutUs: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      aboutUs: _data.ITDOaboutUs1
    });
  }

  render() {
    return (
      <React.Fragment><section
        id="Portafolio4"
        className=" gray-light-bg imageblock-section switchable switchable-content pt-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-7">
              <img
                data-src="https://ik.imagekit.io/tbernaus/itdo%20portafolio/gdb.png?updatedAt=1700223241619"
                src="https://ik.imagekit.io/tbernaus/itdo%20portafolio/gdb.png?updatedAt=1700223241619"
                alt="Guías de Barcelona."
                className="img-fluid img-with-shadow-bl"
              />
            </div>
            <div className="col-lg-5 col-md-5">
              <div className="about-content ml-20">
                <div className="img-logo">
                  <img
                    data-src="https://ik.imagekit.io/tbernaus/itdo%20portafolio/logos/gdb_logo-removebg.png?updatedAt=1700557343652"
                    src="https://ik.imagekit.io/tbernaus/itdo%20portafolio/logos/gdb_logo-removebg.png?updatedAt=1700557343652"
                    alt="Guías de Barcelona"
                    className="img-fluid"
                  />
                </div>
                <p className="right-text">
                  <a href="https://www.guiasdebarcelona.com/">Guias de Barcelona</a> es una organización con más de 15 años de experiencia mostrando
                  Barcelona a más de 2.000 visitantes, resaltando diversión, cultura e historia. Este sitio web ha sido desarrollado en WordPress
                  con funcionalidad de comercio electrónico integrada. Experimenta un sitio web dinámico y atractivo diseñado para impulsar tus ventas en línea.
                </p>
              </div>
            </div>
          </div>
        </div>

      </section>
      </React.Fragment >
    );
  }
}

export default Portafolio4;
