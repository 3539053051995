import React from "react";
import _data from "../../data";

class Portafolio10 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutUs: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      aboutUs: _data.ITDOaboutUs1
    });
  }

  render() {
    return (
      <React.Fragment><section
        id="Portafolio10"
        className=" gray-light-bg imageblock-section switchable switchable-content pt-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-7">
              <img
                data-src="https://ik.imagekit.io/tbernaus/itdo%20portafolio/trac.png?updatedAt=1700223242965"
                src="https://ik.imagekit.io/tbernaus/itdo%20portafolio/trac.png?updatedAt=1700223242965"
                alt="Trac"
                className="img-fluid img-with-shadow-bl"
                width="100%"
                height="200px"
              />
            </div>
            <div className="col-lg-5 col-md-5">
              <div className="about-content ml-20">
                <div className="img-logo">
                  <img
                    data-src="https://ik.imagekit.io/tbernaus/itdo%20portafolio/logos/trac%20logo.png?updatedAt=1700487129236"
                    src="https://ik.imagekit.io/tbernaus/itdo%20portafolio/logos/trac%20logo.png?updatedAt=1700487129236"
                    alt="trac"
                    className="img-fluid"
                  />
                </div>
                <p className="right-text">
                  <a href="https://www.tracrehabilitacio.es/">TRAC</a> es una empresa fundada en 1996, especializada en la rehabilitación
                  y restauración de edificios, un grupo de profesionales con vocación, saber, experiencia y sensibilidad especializados
                  en ofrecer este arte y servicio al cliente. <strong>Este sitio web ha sido meticulosamente diseñado y desarrollado con
                    programación a medida.</strong> La solución personalizada refleja la singularidad de los objetivos y requisitos del proyecto,
                  permitiendo una experiencia en línea completamente única y adaptada a las necesidades específicas. Cada aspecto del
                  sitio demuestra el esfuerzo dedicado a crear una solución exclusiva y precisa.
                </p>
              </div>
            </div>
          </div>
        </div>

      </section>
      </React.Fragment >
    );
  }
}

export default Portafolio10;
