import React, { Component } from "react";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
    };
  }

  componentDidMount() {
    this.fetchArticles();
  }

  fetchArticles() {
    const apiKey = '2fa3724471581153b6457dbe82';
    const apiUrl = 'https://www.itdo.com/blog';
    const { filtre } = this.props;


    const url = filtre ? `${apiUrl}/ghost/api/v3/content/posts/?key=${apiKey}${filtre}` : `${apiUrl}/ghost/api/v3/content/posts/?key=${apiKey}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const recentArticles = data.posts.slice(0, 4);

        this.setState({
          articles: recentArticles,
        });
      })
      .catch((error) => console.error("Error fetching articles:", error));
  }

  render() {
    const { titol } = this.props;

    const limitWords = (text, limit) => {
      const words = text.split(' ');
      if (words.length > limit) {
        return words.slice(0, limit).join(' ') + '...';
      }
      return text;
    };


    return (
      <React.Fragment>
        <section
          id="blog"
          className={
            "package-section ptb-100 " +
            (this.props.bgColor && this.props.bgColor === "white"
              ? ""
              : "gray-light-bg")
          }
        >
          <div className="row justify-content-center">
            <div className="col-md-9">
              <div className="section-heading text-center mb-5">
                <h2>Blog</h2>
                <p className="lead">{titol}</p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {this.state.articles.map((article) => (
                <div key={article.id} className="col-lg-3 col-md-6 pr-0 mt-10">
                  <a href={article.url}>
                    <div className="card blog-post single-pricing-pack h-100">
                      <div className="about-content">
                        <img
                          data-src={article.feature_image}
                          src={article.feature_image}
                          alt={article.title}
                          className="img-fluid"
                          style={{ objectFit: "cover", width: "100%", height: "200px" }}
                        />
                      </div>
                      <div className="feature-content pt-3 p-3">
                        <h4 className="mb-2 fs-15">{limitWords(article.title, 10)}</h4>
                        <p className="fs-13 mb-1">{limitWords(article.excerpt, 15)}</p>
                        <div className="read-more-button" style={{ position: 'absolute', bottom: '5px', left: '15px' }}>
                          <a href={article.url} className="fs-13">
                            Leer más →
                          </a>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>

            <div className="row justify-content-center mt-40">
              <a href="/blog/">Sigue leyendo en nuestro blog → </a>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Blog;
