import React, { Component } from 'react';

class LazyContent extends Component{
    render(){
        return(
            <section id="lazy-loading-content">
            </section>           
        )
    }
}

export default LazyContent;