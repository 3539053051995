import React from "react";
import _data from "../../data";

class Portafolio13 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            aboutUs: {}
        };
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
        this.setState({
            aboutUs: _data.ITDOaboutUs1
        });
    }

    render() {
        return (
            <React.Fragment>
                <section
                    id="Portafolio13"
                    className="imageblock-section switchable switchable-content pt-5">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5 col-md-5">
                                <div className="about-content ml-20">
                                    <div className="img-logo">
                                        <img
                                            data-src="https://ik.imagekit.io/tbernaus/itdo%20portafolio/logos/aud%20logo.png?updatedAt=1700487127612"
                                            src="https://ik.imagekit.io/tbernaus/itdo%20portafolio/logos/aud%20logo.png?updatedAt=1700487127612"
                                            alt="apadrina un destino"
                                            className="img-fluid"
                                        />
                                    </div>
                                    <p>
                                        Apadrina un Destino es Iniciativa solidaria para apoyar a los negocios en su reapertura, ayudándoles a generar ingresos desde
                                        hoy para reducir el impacto de esta crisis en la economía y el empleo del sector. El sitio web desarrollado se compone de un
                                        <strong> e-Commerce personalizado para adaptar la reserva de hoteles y experiencias en bonos canjeables por el cliente.</strong>
                                    </p>

                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7 order-first order-md-last">
                                <img
                                    data-src="https://ik.imagekit.io/tbernaus/itdo%20portafolio/aud.png?updatedAt=1700223241733"
                                    src="https://ik.imagekit.io/tbernaus/itdo%20portafolio/aud.png?updatedAt=1700223241733"
                                    alt="Apadrina un destino"
                                    className="img-fluid img-with-shadow-br"
                                    width="100%" />
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Portafolio13;
