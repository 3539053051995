import React from "react";
import { connect } from "react-redux";
import { getQuote } from "../../actions/index";
import Notifications, { notify } from "react-notify-toast";
import {
	withRouter
} from 'react-router-dom';

class ContactoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      company: "",
      subject: "",
      message: "",
      isAgreed: false,
      disableBtn: false,
      btnText: "CONTACTAR",
      loading:false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  changeBtnText = btnText => {
    this.setState({ btnText });
  };

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  handleSubmit(event) {
    event.preventDefault();

    console.log(this.state);

    // disable the button
    this.setState({ disableBtn: true, loading:true });

    // // get action
    const quoteAction = getQuote(this.state);

    // // Dispatch the contact from data
    this.props.dispatch(quoteAction);
    //Pass it to .env
    const templateId =  "welcome_to_costumer_contact";
    //Send email using EmailJS
    this.sendFeedback(templateId, {
      message_html: this.state.message,
      name: this.state.name,
      email: this.state.email,
      subject: this.state.subject,
      company: this.state.company,
      phone: this.state.phone
    });

    // // added delay to change button text to previous
    setTimeout(
      function() {
        // enable the button
        this.setState({ disableBtn: false, loading:false });

        // change to button name
        this.changeBtnText("CONTACTAR");

        // get action again to update state
        const quoteAction = getQuote(this.state);

        // Dispatch the contact from data
        this.props.dispatch(quoteAction);

        // clear form data
        this.setState({
          name: "",
          email: "",
          company: "",
          phone: "",
          subject: "",
          message: "",
          isAgreed: false
        });
      }.bind(this),
      3000
    );
  }

  /**
   * sendFeedback permite enviar un email al usuario
   * utlizando los seriviocs de EmailJS
   * @param  {[type]} templateId [description]
   * @param  {[type]} variables  [description]
   * @return {[type]}            [description]
   */
  sendFeedback(templateId, variables) {
    window.emailjs
      .send("default_service", templateId, variables)
      .then(res => {
        console.log("Tu contacto ha sido enviado. ¡Gracias!");
		    //window.location.replace("/muchas-gracias-desarrollo-web");
		    this.props.history.push('/muchas-gracias', { response: res, variables:variables });
        /*notify.show(
          "Tu pedido de presupuesto ha sido enviado correctamente. ¡Gracias!",
          "success",
          5000
        );*/
      })
      // Handle errors here however you like, or use a React error boundary
      .catch(err =>
        notify.show(
          `Oh well, you failed. Here some thoughts on the error that occured: ${err}`,
          "error",
          5000
        )
      );
  }

  render() {
    const {title='Contacto', description = 'Rellena este formulario y tendrás tu presupuesto en menos de 24h' } = this.props;
    return (
      <React.Fragment>
        <section
        >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-12 col-lg-12">
              <Notifications options={{zIndex: 200}} />
                <div className="sign-up-form-wrap position-relative rounded p-5 gray-light-bg mt-minus50">
                  <div className="sign-up-form-header text-center mb-4 hide">
                    <h4 className="mb-0">{title}</h4>
                    <p>{description}</p>
                  </div>
                  <form
                    method="post"
                    className="sign-up-form"
                    onSubmit={this.handleSubmit}
                    id="top-form"
                  >
                    <div className="form-group input-group">
                      <label htmlFor="name">Tu nombre o empresa*</label>
                      <input
                        value={this.state.name}
                        onChange={e => this.handleFormValueChange("name", e)}
                        type="text"
                        name="name"
                        id="name"
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="form-group input-group">
                      <label htmlFor="email">Email de contacto</label>
                      <input
                        value={this.state.email}
                        onChange={e => this.handleFormValueChange("email", e)}
                        type="email"
                        name="email"
                        id="email"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group input-group">
                      <label htmlFor="phone">Teléfono de contacto</label>
                      <input
                        value={this.state.phone}
                        onChange={e => this.handleFormValueChange("phone", e)}
                        type="text"
                        name="phone"
                        id="phone"
                        className="form-control"
                      />
                    </div>
                    <div className="form-check d-flex align-items-center text-center">
                      <input
                        checked={this.state.isAgreed}
                        onChange={e =>
                          this.handleFormValueChange("isAgreed", e)
                        }
                        type="checkbox"
                        name="isAgreed"
                        className="form-check-input mt-0 mr-3"
                        id="ckbAgree"
                        required
                      />
                      <label
                        className="form-check-label copyright-text"
                        htmlFor="ckbAgree"
                      >
                        He leído y estoy de acuerdo con la{" "}
                        <a
                          href="https://www.itdo.com/blog/politica-de-privacidad/"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          política de privacidad
                        </a>
                      </label>
                    </div>
                    <div className="form-group mt-10">
                      <input
                        onClick={() => {
                          this.changeBtnText("Enviando...");
                        }}
                        disabled={this.state.disableBtn}
                        type="submit"
                        name="submit"
                        id="submit"
                        className="btn solid-btn btn-block request-quote"
                        value={this.state.btnText}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(withRouter(ContactoSection));
