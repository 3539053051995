import React, {Component} from 'react'

import Header from '../components/Header/header';
import HeroSection from '../components/HeroSection/heroSectionThanks';
import PromoSection from '../components/PromoSection/Thanks';
import Footer from '../components/footer/footer';


class ThanksContact extends Component{
    render(){
      console.log("VALUE FROM PREVIEWS SCREEN",this.props.location.state)//values from previews screen
      //If we need/want the name of the sender, we could do something like: this.props.location.state.variables.from_name
        return(
            <div>
                <Header />
                    <HeroSection title={'¡Muchas gracias ' + this.props.location.state.variables.name + '!'} description={'En breve nos pondremos en contacto contigo. '} backgroundImage='/img/ITDO-freelance5.jpg' />
                    <PromoSection items = {[
					      
					      {
					        title: "Nos pondremos en contacto",
					        description: "En un máximo de 24h nos pondremos en contacto para atender tus necesidades lo más rápido posible.",
					        image: "ti-alarm-clock text-white"
					      },
					      {
					        title: "Analizaremos requisitos",
					        description:
					          "Realizaremos una revisión gratuita de las necesidades del proyecto, concretando contigo las ideas.",
					        image: "ti-search text-white"
					      },
					      {
					        title: "Prepararemos el proyecto",
					        description:
					          "Nuestro equipo aterrizará y concretará la planificación, diseño y desarrollo de tu nueva página web. ",
					        image: "ti-agenda text-white"
					      }
					    ]} />
                <Footer />
            </div>
        )
    }
}

export default ThanksContact
