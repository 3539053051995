import React from "react";
import _data from "../../data";

class Portafolio7 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            aboutUs: {}
        };
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
        this.setState({
            aboutUs: _data.ITDOaboutUs1
        });
    }

    render() {
        return (
            <React.Fragment>
                <section
                    id="Portafolio7"
                    className="imageblock-section switchable switchable-content pt-5">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5 col-md-5">
                                <div className="about-content ml-20">
                                    <div className="img-logo">
                                        <img
                                            data-src="https://ik.imagekit.io/tbernaus/itdo%20portafolio/logos/biopolis%20logo.png?updatedAt=1700487127560"
                                            src="https://ik.imagekit.io/tbernaus/itdo%20portafolio/logos/biopolis%20logo.png?updatedAt=1700487127560"
                                            alt="Biopolis"
                                            className="img-fluid"
                                        />
                                    </div>

                                    <p>
                                        <a href="http://biopolis.world/es">Biopolis</a> es una innovadora y divertida metodología que ayuda a
                                        desarrollar el máximo potencial de las Personas y las Organizaciones mediante el juego y la dinámica de grupo.
                                        Este sitio web ha sido construido a través de la plataforma Drupal, con modulo de e-Commerce, evidenciando
                                        <strong>su versatilidad para desarrollar contenido altamente adaptable e interactivo</strong>. La implementación de módulos
                                        personalizados y su enfoque en la escalabilidad son indicativos del desarrollo. La arquitectura flexible y
                                        las características distintivas de Drupal también se reflejan en la funcionalidad y apariencia del sitio.
                                    </p>

                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7 order-first order-md-last">
                                <img
                                    data-src="https://ik.imagekit.io/tbernaus/itdo%20portafolio/biopolis.png?updatedAt=1700223241530"
                                    src="https://ik.imagekit.io/tbernaus/itdo%20portafolio/biopolis.png?updatedAt=1700223241530"
                                    alt="Biopolis"
                                    className="img-fluid img-with-shadow-br"
                                    width="100%" />
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Portafolio7;
