import React from "react";
import _data from "../../data";

class Portafolio14 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutUs: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      aboutUs: _data.ITDOaboutUs1
    });
  }

  render() {
    return (
      <React.Fragment><section
        id="Portafolio14"
        className=" gray-light-bg imageblock-section switchable switchable-content pt-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-7">
              <img
                data-src="https://ik.imagekit.io/tbernaus/itdo%20portafolio/lcdv.png?updatedAt=1700223242775"
                src="https://ik.imagekit.io/tbernaus/itdo%20portafolio/lcdv.png?updatedAt=1700223242775"
                alt="La comunicación del valor"
                className="img-fluid img-with-shadow-bl"
                width="100%"
                height="200px"
              />
            </div>
            <div className="col-lg-5 col-md-5">
              <div className="about-content ml-20">
                <div className="img-logo">
                  <img
                    data-src="https://ik.imagekit.io/tbernaus/itdo%20portafolio/logos/lcdv%20rmbg.png?updatedAt=1700568155156"
                    src="https://ik.imagekit.io/tbernaus/itdo%20portafolio/logos/lcdv%20rmbg.png?updatedAt=1700568155156"
                    alt="La comunicación del valor"
                    className="img-fluid"
                  />
                </div>
                <p className="right-text">
                  <strong>La Comunicación del Valor</strong> es una jornada anual que se celebra en Madrid con el propósito de promover la responsabilidad social corporativa.
                  El <strong>objetivo establecido fue una mayor Calidad de inscripciones - 50 inscripciones</strong> de calidad para todo el evento.
                  Y el <strong>objetivo alcanzado con la estrategia y seguimiento en redes sociales del equipo de ITDO fue alcanzar 300+ inscritos</strong>.
                  100+ participantes en los dos días de Jornadas. Además de haber generado seguidores fieles y una reputación positiva dentro de la comunidad que
                  potenciará las próximas jornadas.
                </p>
              </div>
            </div>
          </div>
        </div>

      </section >
      </React.Fragment >
    );
  }
}

export default Portafolio14;
