import React from 'react'

const ActionToCall1 = () => {
    return (
        <div className="call-action-1">
            {/* start header section */}
            <section className="call-to-action ptb-100 background-img lazy" id="call-action-1">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-11 mb-3">
                            <div className="call-to-action-content text-center text-white">
                                <h2 className="text-white">Pide tu presupuesto ahora sin compromiso</h2>
                                <p>Tu nueva página web te está esperando, es hora de despegar. Cuenta tu historia con una experiencia digital atractiva para que tu negocio conecte con las personas.</p>
                            </div>
                        </div>
                        <div className="col-11">
                            <div className="call-to-action-btn text-center">
                                <a href="/pedir-presupuesto/desarrollo-web"  className="btn solid-btn">PEDIR PRESUPUESTO</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end header section */}
        </div>
    )
}


export default ActionToCall1;