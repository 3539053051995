import React, { Component } from "react";
import { connect } from "react-redux";
import { submitContact } from "../../actions/index";
import Notifications, { notify } from "react-notify-toast";
import Contract from '../Quote/Contract';


class RequestContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
      disableContactBtn: false,
      contactBtnText: "Send Message",
      loading: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  changeBtnText = contactBtnText => {
    this.setState({ contactBtnText });
  };

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  handleSubmit(event) {
    event.preventDefault();

    // disable the button
    this.setState({ disableContactBtn: true, loading:true });

    // get action
    const contactAction = submitContact(this.state);

    const templateId = this.props.email_template;

    this.sendFeedback(templateId, {
      message_html: this.state.message,
      from_name: this.state.name,
      reply_to: this.state.email,
      from_company: this.state.company,
      from_phone: this.state.phone
    });

    // Dispatch the contact from data
    this.props.dispatch(contactAction);

    // added delay to change button text to previous
    setTimeout(
      function() {
        // enable the button
        this.setState({ disableContactBtn: false, loading:false });

        // change to button name
        this.changeBtnText("Send Message");

        // get action again to update state
        const contactAction = submitContact(this.state);

        // Dispatch the contact from data
        this.props.dispatch(contactAction);

        // clear form data
        this.setState({
          name: "",
          email: "",
          phone: "",
          company: "",
          message: ""
        });
      }.bind(this),
      3000
    );
  }

  /**
   * sendFeedback permite enviar un email al usuario
   * utlizando los seriviocs de EmailJS
   * @param  {[type]} templateId [description]
   * @param  {[type]} variables  [description]
   * @return {[type]}            [description]
   */
  sendFeedback(templateId, variables) {
	  	  
    window.emailjs
      .send("gmail", templateId, variables)
      .then(res => {
        console.log("Message successfully sent!");
	    window.location.replace(this.props.url_success_form);
        notify.show("Message successfully sent!", "success", 5000);
      })
      // Handle errors here however you like, or use a React error boundary
      .catch(err =>
        notify.show(
          `Oh well, you failed. Here some thoughts on the error that occured: ${err}`,
          "error",
          5000
        )
      );
  }

  render() {
    return (
      <React.Fragment>
        <section className="contact-us-section ptb-100-none gray-light-bg" >
        <Notifications options={{zIndex: 200}} />
          <div className="container">
            <div className="row justify-content-between align-items-center">
            <div className="col-md-2"></div>
              <div className="col-md-8">
                <Contract 
                	title='Pide un presupuesto' 
                	description='Rellena este formulario y tendrás tu presupuesto en menos de 24h' 
                	url_success_form = { this.props.url_success_form }
                    email_template = { this.props.email_template } 
                />
                <p className="form-message" />
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(RequestContract);
