import React from "react";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import AgencyCoApp from "./reducers";
import Routes from "./routers";
//import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

//initialize and Track Google Analytics
//ReactGA.initialize('G-J4NNBX9FS7');
//ReactGA.pageview(window.location.pathname + window.location.search);

const tagManagerArgs = {
    gtmId: 'GTM-P3GW9PX'
}
 //initialize and Tag Manager
TagManager.initialize(tagManagerArgs)


// create store
const store = createStore(
  AgencyCoApp,
  composeWithDevTools()
  // applyMiddleware(...middleware),
  // other store enhancers if any
);

function App() {
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
}

export default App;
