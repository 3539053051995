import React from "react"
import { Helmet } from "react-helmet"

const search = () => {
  return (
	<div>
	  <Helmet>
		<script
		  async
		  src="https://cse.google.com/cse.js?cx=a28853a4b9c304f9d"
		></script>
	  </Helmet>

	  <div className="gcse-search"></div>
	</div>
  )
}


export default search